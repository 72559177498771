/* eslint-disable no-param-reassign */
import axios from 'axios';
// import QS from 'qs';
import baseURL from './baseUrl';

const service = axios.create({
  baseURL,
});

// 请求拦截器
service.interceptors.request.use((config) => {
  // 每次发送请求之前本地存储中是否存在token，也可以通过Redux这里只演示通过本地拿到token
  // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
  // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
  // const token = window.localStorage.getItem('userToken')
  //  || window.sessionStorage.getItem('userToken');
  // 在每次的请求中添加token
  // config.data = { ...config.data, token };
  // 设置请求头
  config.headers = {
    'Content-Type': 'application/json',
  };
  // config.data = QS.stringify(config.data);
  return config;
}, (error) => error);

// 响应拦截器
service.interceptors.response.use((response) => {
  // 根据返回不同的状态码做不同的事情
  // 这里一定要和后台开发人员协商好统一的错误状态码
  if (response.status) {
    switch (response.status) {
      case 200:
        return response.data;
      case 401:
        // 未登录处理方法
        break;
      case 403:
        // token过期处理方法
        break;
      default:
      // message.error(response.data.msg);
    }
  } else {
    return response;
  }
});

export default service;
