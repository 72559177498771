/* eslint-disable no-plusplus */
/**
 * @Description
 * @Auth lidongsheng11<lidongsheng11@meituan.com>
 * @Date 2023-01-13 11:00:17
 * @LastEditTime 2024-01-16 19:02:43
 */
const getUrlParams = (url) => {
  const temp1 = `${url}`.split('?') || [];
  const pram = temp1[1];
  const keyValue = pram?.split('&') || [];
  const obj = {};
  for (let i = 0; i < keyValue.length; i++) {
    const item = keyValue[i].split('=');
    const key = item[0];
    const value = item[1];
    obj[key] = value;
  }
  return obj;
};

// 判断是否为移动端
export function isH5Mobile() {
  return document.body.clientWidth <= 768;
}

export default (
  getUrlParams,
  isH5Mobile
);
