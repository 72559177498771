import React, { useEffect, useState, useCallback } from 'react';
import { isH5Mobile } from '../../utils/tools';
import Detail from './detail';
import DetailMobile from './detail_mobile';
import './index.less';

export default () => {
  const [isH5, setIsH5] = useState(isH5Mobile());


  /**
   * 界面宽度变更时触发事件
   */
  const onResize = useCallback(() => {
    setIsH5(isH5Mobile());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return <>{isH5 ? <DetailMobile  /> : <Detail />}</>;
};
