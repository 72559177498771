import { Routes, Route } from 'react-router-dom';
import Home from './pages/home/index';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/success" element={<Success />} /> */}
    </Routes>
  );
}
export default App;
