/**
 * @Description 首页 - 移动端
 * @Auth lidongsheng11<lidongsheng11@meituan.com>
 * @Date 2024-01-31 16:29:42
 * @LastEditTime 2024-01-31 16:54:24
 */
import React, {useEffect, useState} from 'react';
import {Input, Button, notification, List, Tag, Space, Spin} from 'antd';
import { Input as MInput } from 'antd-mobile'
import { SearchOutlined } from '@ant-design/icons';
import fetch from '@/utils/request';
import './detail_mobile.less';

const logo = require('./logo.jpg');

// 文件来源(百度网盘:PAN_BAIDU;夸克网盘:PAN_QUARK;城通网盘:PAN_CT_FILE;百度文库:FILE_BAIDU;网站资源:WEB)
const sourceObj = {
  PAN_BAIDU: '百度网盘',
  PAN_QUARK: '夸克网盘',
  PAN_CT_FILE: '城通网盘',
  FILE_BAIDU: '百度文库',
  WEB: '网站资源',
}

export default () => {
  const [keyword, setKeyword] = useState('');
  const [isResult, setIsResult] = useState(false); // 是否为查询结果页面
  const [isLoading, setIsLoading] = useState(false); // 是否正在加载
  const [resList, setResList] = useState([]); // 查询结果列表
  useEffect(() => {}, []);

  const openPae = (url) => {
    window.open(url);
  }

  const handleSratch = async () => {
    if(!keyword) {
      notification.error({
        message: '错误',
        description: '请输入关键词',
      })
      return;
    }
    try {
      setIsResult(true);
      setIsLoading(true);
      const res:any = await fetch.post('/book/v1/api/pc/book/search/global', {keyword});
      console.log('res:', res);
      if (res.code === 200) {
        setResList(res.data);
      } else {
        notification.error({
          message: '错误',
          description: res?.message || '',
        })
      }
    } catch (error : any) {
      console.log('error:', error);
      notification.error({
        message: '错误',
        description: error?.message || '',
      })
    } finally {
      setIsLoading(false);
    }
  }

  const backToIndex = () => {
    setIsLoading(false);
    setIsResult(false);
    setKeyword('');
    setResList([]);
  }

  return <div className='home-page'>
    {!isResult  ? <div className='main-form'>
      {/* 初始首页 */}
      <div className='logo'>
        <img src={logo} alt='logo' className='logo-img' />
      </div>
      <div className='input'>
        <Input value={keyword} onChange={(e) => setKeyword(e.target.value)} onPressEnter={handleSratch} placeholder='请输入作者或书名' />
      </div>
      <div className='btn'>
        <Button className='btn-wrap' onClick={handleSratch} icon={<SearchOutlined />}>搜索</Button>
      </div>
    </div> : <div className='seach-result'>
      {/* 搜索过后的界面 */}
      <div className='header'>
        <img src={logo} alt='logo' className='res-logo' onClick={() => backToIndex()} />
        <MInput className='res-input' value={keyword} onChange={(v) => setKeyword(v)} onEnterPress={handleSratch} placeholder='请输入作者或书名' />
        {/* <Button className='res-btn' onClick={handleSratch} icon={<SearchOutlined />}>搜索</Button> */}
      </div>
      <div className='res-content'>
        <div className='list-wrap'>
          {isLoading ? <Spin className='loading-wrap' /> : <List
            itemLayout="horizontal"
            dataSource={resList}
            renderItem={( item:any, index) => (
              <List.Item>
                <List.Item.Meta
                  title={<a style={{fontSize:16}} target='_blank' href={item.link}>{item.name}</a>}
                  description={<Space size={[0, 8]} wrap className='tag-list'>
                  {item.disk && <Tag color="#87d068" className='taag-wrap'>{item.disk}</Tag>}
                  {item.format && <Tag color="#108ee9" className='taag-wrap'>{item.format}</Tag>}
                    {item.linkSource && <Tag color="#f50" className='taag-wrap'>{sourceObj[item.linkSource]}</Tag>}
                  </Space>}
                />
              </List.Item>
            )}
          />}
        </div>
      </div>
      </div>}
  </div>
}
